$hex2: polygon(36.257% 0.183%, 85.519% 13.382%, 98.719% 62.645%, 62.656% 98.707%, 13.394% 85.507%, 0.194% 36.244%);

.image-hex {
    display: grid;
    // min-width: 180px;
    width: 100%;
    // max-width: 500px;
    aspect-ratio: 1;

    &__background {
        grid-column: 1;
        grid-row: 1;
        position: relative;
        background-color: $color-highlights;
        -webkit-mask-image: url("../assets/svgs/hex-1.svg");
        mask-image: url("../assets/svgs/hex-1.svg");
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        -webkit-mask-position: center;
    }

    &__foreground {
        grid-column: 1;
        grid-row: 1;
        position: relative;
        // background: $color-highlights;
        clip-path: $hex2;
    }

    &__image-container {
        position: absolute;
        // background: white;
        height: 100%;
        width: 100%;
        // height: calc(100% - 4px);
        // width: calc(100% - 4px);
        // top: 2px;
        // right: 2px;
        clip-path: $hex2;
    }

    &__image {
        width: 100%;
        height: auto;
    }
}