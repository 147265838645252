.curved-foliage {
    display: block;
    width: 100%;
    pointer-events: none;
    z-index: 200;

    img {
        display: block;
        width: 100%;
    }
}

@media (min-height: 400px) {
    .curved-foliage {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }
}