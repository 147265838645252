.order-of-day {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;

    &__item {
        padding: 0;
        margin: 0;
        
        &:not(:last-child) {
            margin-bottom: $spacing-s;
        }
    }

    &__time {
        font-weight: bold;
    }
}