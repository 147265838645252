html { font-size: 18px } /*20px*/

body {
  color: $color-text;
  font-family: $font-body-family;
  font-size: $font-body-size;
  line-height: $font-body-line-height;
  font-weight: $font-body-weight;
  font-style: $font-body-style;
  text-transform: $font-body-transform;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  margin: 0;
}

h1,
.heading-1 {
  font-family: $font-h1-family;
  font-size: $font-h1-size;
  line-height: $font-h1-line-height;
  font-weight: $font-h1-weight;
  font-style: $font-h1-style;
  text-transform: $font-h1-transform;
}

h2,
.heading-2 {
  color: $color-highlights;
  font-family: $font-h2-family;
  font-size: $font-h2-size;
  line-height: $font-h2-line-height;
  font-weight: $font-h2-weight;
  font-style: $font-h2-style;
  text-transform: $font-h2-transform;
}

h3,
.heading-3 {
  font-family: $font-h3-family;
  font-size: $font-h3-size;
  line-height: $font-h3-line-height;
  font-weight: $font-h3-weight;
  font-style: $font-h3-style;
  text-transform: $font-h3-transform;
}

h4,
.heading-4 {
  color: $color-text;
  font-family: $font-h4-family;
  font-size: $font-h4-size;
  line-height: $font-h4-line-height;
  font-weight: $font-h4-weight;
  font-style: $font-h4-style;
  text-transform: $font-h4-transform;
}

h5,
.heading-5 {
  color: $color-text;
  font-family: $font-h5-family;
  font-size: $font-h5-size;
  line-height: $font-h5-line-height;
  font-weight: $font-h5-weight;
  font-style: $font-h5-style;
  text-transform: $font-h5-transform;
}

h6,
.heading-6 {
  font-family: $font-h6-family;
  font-size: $font-h6-size;
  line-height: $font-h6-line-height;
  font-weight: $font-h6-weight;
  font-style: $font-h6-style;
  text-transform: $font-h6-transform;
}

p {
  margin: 0;
}

small,
.text-s {
  font-family: $font-body-s-family;
  font-size: $font-body-s-size;
  line-height: $font-body-s-line-height;
  font-weight: $font-body-s-weight;
  font-style: $font-body-s-style;
  text-transform: $font-body-s-transform;
}

a {
  color: $color-primary-dark;
  transform: color .2s linear;

  &:hover {
    color: darken($color-primary-dark, 20%);
  }
}

@media (min-width: 576px) {
  
  // html { font-size: 26px }

}
