.text-input {
    background: transparent;
    border: 2px solid $color-grey-medium;
    outline: 1px solid transparent;
    border-radius: 30px;
    padding: 12px;
    transition: all 0.2s linear;
    box-shadow: 0px 0px 0px 1px transparent;
    width: 100%;
    color: $color-text;
    font-size: 1rem;
    text-align: left;

    &--number {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
    };

    &__hidden-input {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background: transparent;
    };

    &::placeholder,
    &__hidden-input::placeholder {
        color: rgba($color-grey-medium, .7);
    }

    &:hover { 
        border-color: $color-grey-dark;
    }

    &:focus {
        box-shadow: 0px 0px 0px 2px $color-highlights;
    }
}