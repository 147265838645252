.hero-heading {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: repeat(3, auto);
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    gap: 10px;
    max-width: 350px;

    line-height: 1;

    &__name { 
        grid-column: 1 / -1;
        padding: 0 24px;
    }
    
    &__amp { 
        grid-column: 2 / span 1;
        padding: 0 8px;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        background: $color-highlights;
        height: $highlights-width;
        width: 100%;
        grid-row: 2 / span 1;
        animation: heroIn 1s .3s ease-out;
    }

    &:before {
        grid-column: 1 / span 1;
        justify-self: right;
        transform-origin: center right;
    } 

    &:after {
        grid-column: 3 / span 1;
        justify-self: left;
        transform-origin: center left;
    }
}